import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SessionService } from '../services/session.service';
import { SignoutService } from '../services/signout.service';
import { ONBOARDING_ID_TOKEN_KEY } from '../constants/local-storage.constant';
import { map, mergeMap } from 'rxjs';

export interface DashboardLink {
  name: string;
  path: string;
  icon?: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public links: DashboardLink[] = [
    { path: 'summary', name: '', icon: 'assessment_outline' },
    { path: 'devices', name: 'Devices', icon: 'devices' },
  ];
  public activeLink = this.links[0].path;

  constructor(
    private readonly router: Router,
    private readonly signoutService: SignoutService,
    private readonly snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {}

  public logUserOut() {
    this.signoutService
      .signout()
      .pipe(
        mergeMap(() => {
          console.log('signout')
          return this.snackbar
            .open('You have been logged out!', 'close', {
              duration: 1000,
              panelClass: ['mat-toolbar', 'mat-warn'],
              verticalPosition: 'top',
              horizontalPosition: 'right',
            })
            .afterDismissed()
            .pipe(map(() => localStorage.removeItem(ONBOARDING_ID_TOKEN_KEY)));
        })
      )
      .subscribe({
        next: () => {
          this.router.navigate(['/', 'in', 'signin']);
        },
        error: (error: HttpErrorResponse) => {
          this.snackbar.open('Something went wrong!', 'close', {
            duration: 3000,
            panelClass: ['mat-toolbar', 'mat-warn'],
            verticalPosition: 'top',
            horizontalPosition: 'right',
          });

          console.error(`[Logout]`, error);
        },
      });
  }
}
