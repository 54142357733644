import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  MatDatepicker,
  MatDatepickerInputEvent,
} from '@angular/material/datepicker';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-interval-field',
  templateUrl: './interval-field.component.html',
  styleUrls: ['./interval-field.component.scss'],
})
export class IntervalFieldComponent implements OnInit, OnDestroy {
  public today = new Date();
  public intervals: any[] = [
    { name: 'Today', apiInterval: 'hour' },
    { name: 'Yesterday', apiInterval: 'hour' },
    { name: 'This Week', apiInterval: 'day' },
    { name: 'Last Week', apiInterval: 'day' },
    { name: 'This Month', apiInterval: 'day' },
  ];
  public objectIntervals: any = {
    Today: this.buildInterval(this.today.getDate(), this.today.getDate() + 1),
    Yesterday: this.buildInterval(
      this.today.getDate() - 1,
      this.today.getDate()
    ),
    'This Week': this.buildInterval(
      this.today.getDate() - (this.today.getDay() - 1),
      this.today.getDate() + (7 - this.today.getDay() + 1)
    ),
    'Last Week': this.buildInterval(
      this.today.getDate() - (this.today.getDay() - 1) - 7,
      this.today.getDate() - (this.today.getDay() - 1)
    ),
    'This Month': this.buildInterval(
      1,
      new Date(this.today.getFullYear(), this.today.getMonth(), 0).getDate()
    ),
  };
  public apiInterval = 'hour';
  public defaultIntervalText = 'Interval';
  public intervalSubscriptions: Subscription[] = [];
  public intervalRange: { start?: Date; end?: Date } = {};
  public set _intervalRange(v: any) {
    if (v) {
      this.intervalRange = v;
      this.defaultIntervalText = `${this.datePipe.transform(
        this.intervalRange.start
      )} - ${this.datePipe.transform(this.intervalRange.end)}`;
      this.emitIntervalStringEvent();
    }
  }
  public intervalControl = new UntypedFormControl();
  @Output('intervalFieldChange')
  public intervalFieldChange: EventEmitter<{
    interval: string;
    start: Date;
    end: Date;
  }> = new EventEmitter();

  constructor(
    private cd: ChangeDetectorRef,
    private readonly datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this._intervalRange = {
      start: new Date(
        this.today.getFullYear(),
        this.today.getMonth(),
        this.today.getDate(),
        0,
        0
      ),
      end: new Date(
        this.today.getFullYear(),
        this.today.getMonth(),
        this.today.getDate() + 1,
        0,
        0
      ),
    };

    this.intervalSubscriptions.push(
      this.intervalControl.valueChanges.subscribe((d) => {
        if (d) {
          this._intervalRange = this.objectIntervals[d];
          this.cd.detach();
          this.cd.reattach();
          this.cd.detectChanges();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.intervalSubscriptions.forEach((s) => s.unsubscribe());
  }

  public emitIntervalStringEvent() {
    this.intervalFieldChange.emit({
      interval: this.apiInterval,
      start: this.intervalRange.start as Date,
      end: this.intervalRange.end as Date,
    });
  }

  public setApiInterval(v: any): void {
    this.apiInterval = v;
    this.emitIntervalStringEvent();
  }

  public buildInterval(startDay?: any, endDay?: any): any {
    return {
      start: new Date(
        `${new Date(
          this.today.getFullYear(),
          this.today.getMonth(),
          startDay ?? this.today.getDate() - 1,
          0,
          0
        )}`
      ),
      end: new Date(
        `${new Date(
          this.today.getFullYear(),
          this.today.getMonth(),
          endDay ?? this.today.getDate() + 1,
          0,
          0
        )}`
      ),
    };
  }

  public dateRangeChange(e: MatDatepickerInputEvent<Date>, mode: string): void {
    this.apiInterval = 'day';
    (this.intervalRange as any)[mode] = e.value;
    this.defaultIntervalText = `${this.datePipe.transform(
      this.intervalRange.start
    )} - ${this.datePipe.transform(this.intervalRange.end)}`;
  }

  public openCustomIntervalPicker(picker: MatDatepicker<any>): void {
    picker.open();
  }
}
