import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, map, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { SessionService } from '../services/session.service';

@Injectable()
export class SessionExpiredInterceptor implements HttpInterceptor {
  constructor(
    private readonly router: Router,
    private readonly sessionService: SessionService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request.clone()).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse && error.status === 403) {
          console.error(`Session expired: ${error.message}`);
          this.sessionService.removeSession();
          this.router.navigate(['/in', '/signin']);
        }

        return throwError(() => error);
      }),
      map((event: HttpEvent<unknown>) => event)
    );
  }
}
