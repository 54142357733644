<div *ngIf="device; else loadingDevice;" class="d-flex flex-column w-100">
    <!-- INFO Device Name and control -->
    <div class="d-flex flex-wrap w-100 mt-3 mb-3">
        <div *ngIf="device" class="d-flex my-auto">
            <mat-icon class="my-auto">wifi_home</mat-icon>
            <span class="ms-2 my-auto mat-h2">{{ device.name }}</span>

            <button mat-flat-button class="w-fit-content h-fit-content ms-2 my-auto px-1 rounded-3"
                [color]="!isOnline ? 'warn' : 'primary'">
                <span class="mat-caption">{{ isNowOffline ? 'Offline' : 'Online' }}</span>
            </button>
        </div>

        <!-- INFO Powered -->
        <mat-slide-toggle class="ms-auto mt-3 my-sm-auto" [checked]="device.powered"
            (change)="toggleDevicePowerStatus($event)">Power {{ device.powered ? 'On' : 'Off' }}</mat-slide-toggle>
    </div>

    <!-- INFO Interval -->
    <app-interval-field class="ms-auto w-90 w-sm-50 w-md-75 w-lg-40 mt-3"
        (intervalFieldChange)="intervalChange($event)"></app-interval-field>

    <!-- INFO Stats -->
    <div class="d-flex w-100 mt-3" style="overflow-x: auto;">
        <mat-card
            class="d-flex flex-column col-11 col-sm-7 col-md-6 col-lg-5 col-xl-4 me-1 p-2 p-sm-3 rounded-3 shadow-none">
            <span class="mat-caption fw-bold">Total Energy Consumed</span>

            <div class="d-flex w-100">
                <span class="ms-auto mb-0 mat-headline-3">{{ sumTotalOfEnergy / 1000 }}</span>
                <span class="mb-auto mat-caption"> kWh</span>
            </div>
        </mat-card>

        <mat-card
            class="d-flex flex-column col-11 col-sm-7 col-md-6 col-lg-5 col-xl-4 ms-1 p-2 p-sm-3 rounded-3 shadow-none">
            <span class="mat-caption fw-bold">Total Power</span>

            <div class="d-flex w-100">
                <span class="ms-auto mb-0 mat-headline-3">{{ sumTotalOfPower | number:'1.0-2' }}</span>
                <span class="mb-auto mat-caption">W</span>
            </div>
        </mat-card>
    </div>

    <!-- INFO Network Information -->
    <div *ngIf="networkInformation" class="d-flex w-100 mt-2 mb-3">
        <mat-card class="d-flex w-100  p-2 p-sm-3 rounded-3 shadow-none">
            <div class="d-flex w-100 mb-0">
                <span class="mat-caption">Last seen at {{lastSeen | date:'MMM dd, yyyy hh:mm:ss'}}</span>
                <button mat-icon-button class="ms-auto" inline="true" color="warn">
                    <mat-icon>edit</mat-icon>
                </button>
            </div>

            <!-- others -->
            <div class="d-flex flex-column w-100">
                <div class="d-flex w-100 ms-2 mt-2 mat-caption">
                    <mat-icon class="me-2" color="primary">wifi</mat-icon>
                    <span class="mb-0 mat-body-1">{{ networkInformation.ssid }}</span>
                </div>

                <div class="d-flex w-100 mt-2">
                    <mat-chip class="me-2 text-captialize" color="warn" style="width: fit-content;">
                        <span class="text-capitalize">BSSID: {{networkInformation.bssid}}</span>
                    </mat-chip>

                    <mat-chip class="me-2 text-captialize" color="warn" style="width: fit-content;">
                        <span class="text-capitalize">RSSI:
                            {{networkInformation.rssi}}</span>
                    </mat-chip>

                    <mat-chip class="me-2 text-captialize" color="warn" style="width: fit-content;">
                        <span class="text-capitalize">signal level: {{networkInformation.signal}}</span>
                    </mat-chip>

                    <mat-chip class="me-2 text-captialize" color="warn" style="width: fit-content;">
                        <span class="text-capitalize">channel:
                        {{networkInformation.channel}}</span>
                    </mat-chip>

                    <mat-chip class="me-2 text-captialize" color="warn" style="width: fit-content;">
                        <span class="text-capitalize">mode: {{networkInformation.mode}}</span>
                    </mat-chip>
                </div>
            </div>
        </mat-card>
    </div>

    <!-- INFO Graph -->
    <div class="d-flex w-100">
        <mat-card
            class="d-flex flex-column w-100 mt-3 mb-3 p-2 p-sm-0 shadow-none rounded-3 imole-device-overview-graph">
            <mat-progress-bar *ngIf="dataLoaded === false" class="w-100" mode="query" color="warn"></mat-progress-bar>

            <!-- INFO INFO Caption Text-->
            <div class="d-flex px-2">
                <caption class="fw-bolder mat-heading-3 text-gray">Overview</caption>
            </div>

            <app-bar-chart class="d-flex w-100 h-100"
                [series]="[{type: 'bar', name: 'Average Consumption', data: averageConsumption}, {type: 'bar', name: 'Total Consumption', data: totalConsumption}]"></app-bar-chart>
        </mat-card>
    </div>

    <!-- INFO Control -->
    <div class="d-flex flex-column w-100 mt-4">
        <mat-form-field class="col-12 col-sm-8 col-md-6 col-xl-4" appearance="outline">
            <mat-label>Device Name</mat-label>
            <input matInput type="text" [formControl]="deviceNameControl">
        </mat-form-field>

        <button mat-flat-button class="w-fit-content mt-3 rounded-3" color="primary"
            [disabled]="deviceNameControl.invalid">Save</button>
    </div>

    <!-- INFO Invite Users -->
</div>

<ng-template #loadingDevice>
    <div class="d-flex flex-column w-100 h-100">
        <img class="mx-auto mt-quto" src="https://storage.googleapis.com/gci-static-assets/imole/loading.gif"
            alt="Device">
        <span class="mx-auto mt-3 mb-auto mat-subtitle-1">Loading Device Information...</span>
    </div>
</ng-template>
