import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { SHA1 } from 'crypto-js';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnInit, DoCheck {
  @Input() public data: any[] = [];
  public options: EChartsOption = {
    legend: {
      show: true,
      bottom: 5,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    // color: ['#f48400'],
    xAxis: {
      type: 'time',
      show: true,
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisLabel: {
        show: true,
        align: 'left',
        verticalAlign: 'middle',
      },
    },
    yAxis: {
      type: 'value',
      show: true,
      axisTick: {
        show: false,
      },
      splitLine: {
        show: true,
      },
      axisLine: {
        show: false,
      },
    },
    series: [
      {
        data: [],
        type: 'bar',
      },
    ],
  };
  public lastHash!: string;
  @Input() public series: any[] = [];

  constructor() {}

  ngOnInit(): void {
    if (this.series.length > 0) {
      this.options = { ...this.options, series: this.series };
    } else {
      (this.options.series as any)[0] = {
        ...(this.options.series as any)[0],
        data: this.data,
      };
    }
  }

  ngDoCheck(): void {
    if (this.series.length > 0) {
      const hash = SHA1(JSON.stringify(this.series)).toString();

      if (this.lastHash != hash) {
        this.lastHash = hash;
        this.options = {
          ...this.options,
          series: this.series,
        };
      }
    } else {
      const hash = SHA1(JSON.stringify(this.data)).toString();

      if (this.lastHash != hash) {
        this.lastHash = hash;
        this.options = {
          ...this.options,
          series: [
            { ...(this.options?.series as any)[0], data: [...this.data] },
          ],
        };
      }
    }
  }
}
