import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AnalyticsOverviewPayload,
  AnalyticsResponse,
  AssetEventData,
  CurrentDataResponse,
} from '../interfaces/analytics';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private baseUrl = `//localhost:7492`;

  constructor(private readonly http: HttpClient) {}

  public getAssetEventData(payload: AssetEventData) {
    return this.http.get<AssetEventData[]>(`${this.baseUrl}/analytics/events/${payload.sid}`, {
      params: new HttpParams().appendAll(payload as any),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  public getCurrentData(payload: string) {
    return this.http.get<CurrentDataResponse>(
      `${this.baseUrl}/analytics/latest`,
      {
        params: new HttpParams().append('sid', payload),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  public getSensorAggregatedData(payload: AnalyticsOverviewPayload) {
    return this.http.get<AnalyticsResponse<number>>(
      `${this.baseUrl}/analytics/sensor`,
      {
        params: new HttpParams().appendAll(payload as any),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  public getOverviewData(payload: AnalyticsOverviewPayload) {
    return this.http.get<AnalyticsResponse<[number, number]>>(
      `${this.baseUrl}/analytics/overview`,
      {
        params: new HttpParams().appendAll(payload as any),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }
}
