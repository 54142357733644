import { Component } from '@angular/core';
import { IDevice } from '../interfaces/device.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceService, IGetAggregatedData } from '../services/device.service';
import { API_PAGE_LIMIT } from '../constants/api.constant';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss'],
})
export class DevicesComponent {
  public devices: IDevice[] = [];

  constructor(
    private readonly snackbar: MatSnackBar,
    private readonly deviceService: DeviceService
  ) {}

  ngOnInit() {
    this.getDevices();
  }

  public getDevices() {
    const limit = API_PAGE_LIMIT;
    const offset = this.devices.length;

    // INFO call api here
    this.deviceService.listDevices(limit, offset).subscribe({
      next: (response) => {
        this.devices = [...this.devices, ...response];
      },
      error: (error) => {
        console.error(`[getDevices]`, error);

        this.snackbar.open('An error occurred!', 'close', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          panelClass: ['bg-danger', 'text-white'],
        });
      },
    });
  }
}
