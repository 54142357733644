import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { map, mergeMap } from 'rxjs';
import { FirebaseAuthService } from '../services/firebase-auth.service';
import { ONBOARDING_ID_TOKEN_KEY } from '../constants/local-storage.constant';
import { SignupService } from '../services/signup.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  public passwordFieldConfig = { password: true, confirm: true };
  private signupForm!: UntypedFormGroup;
  public inProcess: boolean = false;
  public showError: boolean = false;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly firebaseAuthService: FirebaseAuthService,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly signupService: SignupService,
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  public signup() {
    const signupData = this.signupForm.getRawValue();

    this.firebaseAuthService
      .createUserWithEmailAndPassword(signupData.email, signupData.password)
      .pipe(
        mergeMap(() => {
          return this.firebaseAuthService.getIdToken();
        }),
        mergeMap((data) => {
          const idTokenResult = data;

          // INFO Always unverified
          return this.firebaseAuthService.updateProfile({
            displayName: signupData.name,
          }).pipe(map(() => idTokenResult));
        }),
        mergeMap((idTokenResult) => {
          localStorage.setItem(ONBOARDING_ID_TOKEN_KEY, idTokenResult.token);

          return this.signupService.createProfile().pipe(map(() => idTokenResult))
        }),
      )
      .subscribe({
        next: () => {
          // INFO Route to verify email page
          this.router.navigate(['/', 'in', 'signin']);
          this.form.reset();
          this.inProcess = false;
        },
        error: () => {
          this.inProcess = false;
          this.snackBar.open('An error occurred!', 'close', {
            verticalPosition: 'top',
            horizontalPosition: 'end',
            panelClass: [
              'bg-danger',
              'text-white',
              'rounded-3e',
              'modified-snackbar',
            ],
          });
          this.showError = true;
        },
      });
  }

  private initForm() {
    this.signupForm = this.fb.group(
      {
        name: new UntypedFormControl(null, []),
        email: new UntypedFormControl(null, [Validators.required, Validators.email]),
        password: new UntypedFormControl(null, [
          Validators.required,
          Validators.minLength(6),
        ]),
        confirm_password: new UntypedFormControl(null, [
          Validators.required,
          Validators.minLength(6),
        ]),
      },
      {
        validators: (g: AbstractControl) => {
          return g.get('password')?.value === g.get('confirm_password')?.value
            ? null
            : { mismatch: true };
        },
      }
    );
  }

  public get controls() {
    return this.signupForm.controls;
  }

  public get form(): UntypedFormGroup {
    return this.signupForm;
  }
}
