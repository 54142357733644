import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Asset, UpdateBody } from '../interfaces/asset';

@Injectable({
  providedIn: 'root',
})
export class AssetsService {
  private baseUrl = `//localhost:7491`;

  constructor(private readonly http: HttpClient) { }

  public createAsset(items: Asset[]): Observable<Asset> {
    return this.http.post<Asset>(`${this.baseUrl}/assets`, items, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }
  
  public deleteAsset(item: Partial<Asset>): Observable<{ input: Asset; details: any; }> {
    return this.http.delete<{ input: Asset; details: any; }>(`${this.baseUrl}/assets`, {
      params: new HttpParams().appendAll(item as any),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  public updateAsset(id: string, item: UpdateBody[]): Observable<{ input: Asset; details: any }> {
    return this.http.patch<{ input: Asset; details: any }>(
      `${this.baseUrl}/assets/${id}`,
      item,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  public getAllAssets(item?: Partial<Asset>): Observable<Asset[]> {
    return this.http.get<Asset[]>(`${this.baseUrl}/assets`, {
      params: item ? new HttpParams().appendAll(item as any) : {},
      headers: {
        'Content-Type': 'application/json',
      },
      observe: 'body',
      responseType: 'json',
    });
  }
}
