<main class="d-flex flex-column">
    <mat-toolbar class="d-flex flex-column bg-white">
        <mat-toolbar-row class="p-0 border-bottom">
            <div class="d-flex container">
                <app-imole-logo-horizontal class="ms-3 ms-sm-0 my-auto" [style.width]="'232px'" [style.height]="'48px'"></app-imole-logo-horizontal>

                <a mat-button class="ms-auto my-auto rounded text-danger" (click)="logUserOut()" color="warn">
                    <mat-icon class="my-auto">logout_outline</mat-icon>
                    <span class="ms-2 my-auto fw-bold">Logout</span>
                </a>
            </div>
        </mat-toolbar-row>

        <mat-toolbar-row class="p-0 border-bottom">
            <div class="container py-1">
                <nav mat-tab-nav-bar class="w-100 border-0" [tabPanel]="tabPanel">
                    <a mat-tab-link *ngFor="let link of links" class="m-0 text-capitalize"
                        [active]="activeLink == link.path" [class]="activeLink == link.path ? 'text-white' : 'text-dark'"
                        [routerLink]="[link.path]" routerLinkActive
                        (isActiveChange)="$event ? activeLink = link.path : link.path">
                        <mat-icon class="my-auto" [color]="activeLink === link.path ? null : 'accent'">{{ link.icon }}</mat-icon>
                        <span *ngIf="link.name" class="ms-2 my-auto">{{link.name}}</span>
                    </a>
                </nav>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>

    <mat-tab-nav-panel #tabPanel class="h-100">
        <div class="container py-3">
            <router-outlet></router-outlet>
        </div>
    </mat-tab-nav-panel>
</main>
