import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { interval, map, mergeMap } from 'rxjs';
import { ISignin } from '../interfaces/authentication-data';
import { SigninService } from '../services/signin.service';
import { FirebaseAuthService } from '../services/firebase-auth.service';
import { ONBOARDING_ID_TOKEN_KEY } from '../constants/local-storage.constant';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit {
  public passwordFieldConfig = { status: true };
  private signinForm!: UntypedFormGroup;
  public inProcess = false;
  public showError: boolean = false;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private router: Router,
    private readonly signinService: SigninService,
    private readonly snackBar: MatSnackBar,
    private readonly firebaseAuthService: FirebaseAuthService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  public async signIn() {
    this.inProcess = true;
    this.showError = false;
    const value = this.form.getRawValue();

    this.firebaseAuthService
      .signInWithEmailAndPassword(value.email, value.password)
      .pipe(
        mergeMap((userCredentials) => {
          return this.firebaseAuthService
            .getIdToken()
            .pipe(map((idTokenResult) => ({ userCredentials, idTokenResult })));
        }),
        mergeMap((data) => {
          const idTokenResult = data.idTokenResult;

          localStorage.setItem(ONBOARDING_ID_TOKEN_KEY, idTokenResult.token);

          // INFO isVerified
          // if (data.userCredentials.user?.emailVerified) {
          // INFO call for authenticate user
          return this.signinService.verifyUser().pipe(
            mergeMap(() => {
              localStorage.setItem('all', JSON.stringify(data));

              return this.router.navigate(['/', 'board', 'summary']);
            })
          );
          // }
        })
      )
      .subscribe({
        next: () => {
          this.form.reset();
          this.inProcess = false;
        },
        error: () => {
          this.inProcess = false;
          this.snackBar.open('An error occurred!', 'close', {
            verticalPosition: 'top',
            horizontalPosition: 'end',
            panelClass: [
              'bg-danger',
              'text-white',
              'rounded-3e',
              'modified-snackbar',
            ],
          });
          this.showError = true;
        },
      });
  }

  private initForm() {
    this.signinForm = this.fb.group({
      email: new UntypedFormControl(null, [
        Validators.required,
        Validators.email,
      ]),
      password: new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(6),
      ]),
    });
  }

  public get controls() {
    return this.signinForm.controls;
  }

  public get form(): UntypedFormGroup {
    return this.signinForm;
  }
}
