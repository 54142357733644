import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { from, map, mergeMap, throwError } from 'rxjs';
import { VERIFY_EMAIL_ROUTE } from '../constants/routes.constant';

@Injectable({
  providedIn: 'root',
})
export class FirebaseAuthService {
  public verifyEmailPath: string = VERIFY_EMAIL_ROUTE;

  constructor(private readonly angularAuth: AngularFireAuth) { }
  
  public signOut() {
    return from(this.angularAuth.signOut());
  }

  public getUserProfile() {
    return from(this.angularAuth.user);
  }

  // INFO https://firebase.google.com/docs/reference/js/auth.md#applyactioncode_d2ae15a
  public applyActionCode(code: string) {
    return from(this.angularAuth.applyActionCode(code));
  }

  public updateProfile(profileDetails: {
    displayName?: string;
    photoURL?: string;
  }) {
    return this.angularAuth.user.pipe(
      mergeMap((user) => {
        if (!user) {
          return throwError(() => 'No user Available');
        }

        return user?.updateProfile(profileDetails);
      })
    );
  }

  public getIdToken() {
    return this.angularAuth.idTokenResult.pipe(
      map((result) => {
        if (!result) {
          throw new Error('No token available!');
        }

        return result;
      })
    );
  }

  public signInWithEmailAndPassword(email: string, password: string) {
    return from(this.angularAuth.signInWithEmailAndPassword(email, password));
  }

  public signInWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();

    provider.addScope('email');
    provider.addScope('profile');

    return this.angularAuth.signInWithRedirect(provider);
  }

  public getRedirectResult() {
    return this.angularAuth.getRedirectResult();
  }

  public createUserWithEmailAndPassword(email: string, password: string) {
    return from(
      this.angularAuth.createUserWithEmailAndPassword(email, password)
    );
  }

  public confirmPasswordReset(code: string, newPassword: string) {
    return from(this.angularAuth.confirmPasswordReset(code, newPassword));
  }

  public verifyPasswordResetCode(code: string) {
    return from(this.angularAuth.verifyPasswordResetCode(code));
  }

  /**
   * This is deprecated and should not be used
   * @deprecated
   */
  public sendPasswordResetEmail(email: string) {
    return from(this.angularAuth.sendPasswordResetEmail(email));
  }

  // INFO https://rubular.com/r/UAwoaPM0Ji
  /**
   * This is deprecated and should not be used
   * @deprecated
   */
  public sendUserEmailVerification(user?: firebase.User) {
    if (user) {
      return from(
        user.sendEmailVerification({
          url: 'http://localhost:4200/' + this.verifyEmailPath,
          handleCodeInApp: false,
        })!
      );
    }

    return this.angularAuth.user.pipe(
      mergeMap((u) => {
        return from(
          u?.sendEmailVerification({
            url: 'localhost:4200/' + this.verifyEmailPath,
          })!
        );
      })
    );
  }
}
