import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InventoryItem } from '../interfaces/inventory-item';

@Injectable({
  providedIn: 'root',
})
export class InventoryService {
  private baseUrl = `//localhost:7491`;

  constructor(private readonly http: HttpClient) { }
  
  public addInventoryItem(items: Partial<InventoryItem>[]): Observable<InventoryItem[]> {
    return this.http.post<InventoryItem[]>(`${this.baseUrl}/assets/inventory`, items, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  public getAllInventoryItems(): Observable<InventoryItem[]> {
    return this.http.get<InventoryItem[]>(`${this.baseUrl}/assets/inventory`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}
