import { Component } from '@angular/core';

@Component({
  selector: 'app-box-empty',
  templateUrl: './box-empty.component.html',
  styleUrls: ['./box-empty.component.scss']
})
export class BoxEmptyComponent {

}
