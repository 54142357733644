import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ONBOARDING_ID_TOKEN_KEY } from '../constants/local-storage.constant';
import { from, of } from 'rxjs';
import { FirebaseAuthService } from './firebase-auth.service';

@Injectable({
  providedIn: 'root',
})
export class SignoutService {
  private baseUrl = `//localhost:7490`;

  constructor(private readonly firebaseAuthService: FirebaseAuthService) {}

  public signout() {
    return this.firebaseAuthService.signOut();
  }
}
