import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceService } from '../services/device.service';
import { IChannel } from '../interfaces/channel.interface';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { IDevice } from '../interfaces/device.interface';
import { delay, mergeMap } from 'rxjs';
import { ICreateDeviceDto } from '../interfaces/create-device.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-device-setup',
  templateUrl: './device-setup.component.html',
  styleUrls: ['./device-setup.component.scss'],
})
export class DeviceSetupComponent {
  public isEditable: boolean = false;
  // INFO https://www.oreilly.com/library/view/regular-expressions-cookbook/9780596802837/ch07s16.html
  public ipControl: FormControl = new FormControl(null, [
    Validators.required,
    Validators.pattern(
      '^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$'
    ),
  ]);
  public hidePassword: boolean = true;
  public networkForm!: FormGroup;
  public callInProgress: boolean = false;
  public addDeviceForm!: FormGroup;
  public channelForm!: FormGroup;
  public channels: IChannel[] = [];
  public hasChannels: boolean = false;

  constructor(
    private readonly deviceService: DeviceService,
    private snackbar: MatSnackBar,
    private readonly formBuilder: FormBuilder,
    public readonly router: Router,
  ) {}

  ngOnInit() {
    this.initializeForm();
  }

  public addDevice(stepper?: MatStepper) {
    const data: ICreateDeviceDto = this.addDeviceForm.getRawValue();

    data.channels! = this.channels;
    data.baseUrl = this.ipControl.getRawValue();

    return this.deviceService
      .addDevice(data)
      .pipe(delay(10000))
      .subscribe({
        next: (id) => {
          this.callInProgress = false;
          this.addDeviceForm.reset();
          this.setTopic(id);

          if (stepper) {
            stepper.next();
          }
        },
        error: (error) => {
          this.callInProgress = false;
          console.error(`[addDevice]`, error);

          this.snackbar.open('An error occurred!', 'close', {
            verticalPosition: 'top',
            horizontalPosition: 'end',
            panelClass: ['bg-danger', 'text-white'],
          });
        },
      });
  }

  public removeChannel(index: number) {
    this.channels.splice(index, 1);
  }

  public getControl(source: FormGroup, key: string) {
    return source.controls[key] as FormControl;
  }

  public shouldAddChannel(e: MatCheckboxChange) {
    this.hasChannels = e.checked;

    if (!this.hasChannels) {
      this.channels = [];
    } else {
      this.addDeviceForm.controls['powered'].setValue(false);
    }
  }

  public addChannel() {
    const channelDetails = this.channelForm.getRawValue();

    this.channels.push(channelDetails);
    this.channelForm.reset({ powered: false, status: true });
  }

  public setWiFiOptions(stepper?: MatStepper) {
    const data = {
      baseUrl: this.ipControl.getRawValue(),
      ...this.networkForm.getRawValue(),
    };

    this.deviceService.setLocalWiFiOptions(data).subscribe({
      next: () => {
        this.snackbar.open('Successful!', 'close', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          panelClass: ['bg-primary'],
        });
        this.router.navigate(['/', 'board', 'devices']);
      },
      error: (error) => {
        console.error(`[setWiFiOptions]`, error);

        // this.snackbar.open('An error occurred!', 'close', {
        //   verticalPosition: 'top',
        //   horizontalPosition: 'end',
        //   panelClass: ['bg-danger', 'text-white'],
        // });

        // if (stepper) {
        //   stepper.next();
        // }
      },
    });
  }

  public setTopic(id: string) {
    this.callInProgress = true;
    const data = { baseUrl: this.ipControl.getRawValue() };

    return this.deviceService
      .setLocalTopic({ topic: id, ...data })
      .subscribe({
        next: () => {
          this.callInProgress = false;
        },
        error: (error) => {
          this.callInProgress = false;
          console.error(`[setMQTTOptions]`, error);

          // this.snackbar.open('An error occurred!', 'close', {
          //   verticalPosition: 'top',
          //   horizontalPosition: 'end',
          //   panelClass: ['bg-danger', 'text-white'],
          // });
          // if (stepper) {
          //   stepper.next();
          // }
        },
      });
  }

  public setMQTTOptions(stepper?: MatStepper) {
    this.callInProgress = true;
    const data = { baseUrl: this.ipControl.getRawValue() };

    return this.deviceService
      .setLocalMQTTOptions(data)
      .subscribe({
        next: () => {
          this.callInProgress = false;

          if (stepper) {
            stepper.next();
          }
        },
        error: (error) => {
          this.callInProgress = false;
          console.error(`[setMQTTOptions]`, error);

          // this.snackbar.open('An error occurred!', 'close', {
          //   verticalPosition: 'top',
          //   horizontalPosition: 'end',
          //   panelClass: ['bg-danger', 'text-white'],
          // });
          if (stepper) {
            stepper.next();
          }
        },
      });
  }

  public skipStep(stepper: MatStepper) {
    stepper.next();
  }

  public saveIPAddress(stepper: MatStepper) {
    this.setMQTTOptions(stepper);
  }

  public initializeForm() {
    this.networkForm = this.formBuilder.group({
      ssid: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required]),
    });
    this.addDeviceForm = this.formBuilder.group({
      name: new FormControl(null, [Validators.required]),
      powered: new FormControl(false, [Validators.required]),
      status: new FormControl(true, [Validators.required]),
    });
    this.channelForm = this.formBuilder.group({
      name: new FormControl(null, [Validators.required]),
      position: new FormControl(null, [Validators.required, Validators.min(1)]),
      powered: new FormControl(false, [Validators.required]),
      status: new FormControl(true, [Validators.required]),
    });
  }
}
