<div class="d-flex flex-column">
    <span class="mb-4 mat-h1">{{ mainHeader }}</span>

    <div class="d-flex flex-wrap" style="justify-content: flex-start;">
        <div *ngFor="let device of devices; index as i; odd as isOdd; even as isEven; first as isFirst; last as isLast"
            class="d-flex col-12 col-md-6 col-lg-4 mb-2 mb-sm-3"
            [ngClass]="{'ps-md-1': !isFirst && isOdd, 'pe-md-1': !isLast && isEven && devices.length > 1, 'pe-lg-1': isOdd, 'ps-lg-1': isEven}">
            <app-device class="w-100 h-100" [device]="device"></app-device>
        </div>
    </div>
</div>
