import { NgModule, inject } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { AssetComponent } from './asset/asset.component';
import { AssetsComponent } from './assets/assets.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ActivateChildGuard } from './guards/activate-child.guard';
import { LoginGuard } from './guards/login.guard';
import { InventoryComponent } from './inventory/inventory.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { SummaryComponent } from './summary/summary.component';
import { UsersComponent } from './users/users.component';
import { DeviceSetupComponent } from './device-setup/device-setup.component';
import { DevicesComponent } from './devices/devices.component';
import { DeviceOverviewComponent } from './device-overview/device-overview.component';
import { SessionService } from './services/session.service';

const routes: Routes = [
  {
    path: 'board',
    canActivate: [
      () => {
        const router = inject(Router);
        const sessionService = inject(SessionService);

        try {
          const t = sessionService.token;

          return t
            ? true
            : router.navigate(['/', 'in', 'signin']).then(() => false);
        } catch {
          return router.navigate(['/', 'in', 'signin']).then(() => false);
        }
      },
    ],
    component: DashboardComponent,
    children: [
      {
        path: 'devices',
        pathMatch: 'prefix',
        children: [
          {
            path: 'setup',
            pathMatch: 'full',
            component: DeviceSetupComponent,
          },
          {
            path: 'overview/:id',
            pathMatch: 'full',
            component: DeviceOverviewComponent,
          },
          {
            path: '',
            pathMatch: 'full',
            component: DevicesComponent,
          },
          {
            path: '**',
            redirectTo: '/board/summary',
          },
        ],
      },
      {
        path: 'users',
        pathMatch: 'full',
        component: UsersComponent,
      },
      {
        title: 'Summary - Imole',
        path: 'summary',
        pathMatch: 'full',
        component: SummaryComponent,
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/board/summary',
      },
      {
        path: '**',
        redirectTo: '/board',
      },
    ],
  },
  {
    path: 'in',
    component: OnboardingComponent,
    children: [
      {
        title: 'Sign In - Imole',
        path: 'signin',
        pathMatch: 'full',
        canActivate: [
          () => {
            const sessionService = inject(SessionService);
            const router = inject(Router);

            try {
              const t = sessionService.token;

              return t
                ? router.navigate(['/', 'board', 'summary']).then(() => false)
                : true;
            } catch {
              return router
                .navigate(['/', 'board', 'summary'])
                .then(() => true);
            }
          },
        ],
        component: SigninComponent,
      },
      {
        title: 'Sign Up - Imole',
        path: 'signup',
        pathMatch: 'full',
        component: SignupComponent,
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/in/signin',
      },
      {
        path: '**',
        redirectTo: '/in/signin',
      },
    ],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'in/signin',
  },
  {
    path: '**',
    redirectTo: 'in/signin',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
