<mat-card class="d-flex flex-column w-100 h-100 px-3 py-3 rounded-3 shadow-none">
    <!-- INFO Device details -->
    <div class="d-flex flex-row">
        <mat-icon class="material-symbols-outlined" color="primary">switch</mat-icon>
        <span *ngIf="device" class="ms-2 me-2 my-auto">{{ device.name }}</span>

        <!-- INFO ON/OFF -->
        <mat-slide-toggle *ngIf="device.channels.length === 0" class="ms-auto"
            [checked]="device.powered" (change)="toggleDevicePowerStatus($event)"></mat-slide-toggle>
    </div>

    <div class="d-flex w-100 mt-3">
        <span *ngIf="device.channels.length > 0" class=" my-auto mat-small">channels: {{ device.channels.length }}</span>

        <!-- INFO Date Created -->
        <span class="my-auto mat-small" [ngClass]="{'ms-2': device.channels.length > 0}">{{ device.time | date }}</span>

        <!-- INFO open button -->
        <button mat-button class="d-flex ms-auto my-auto p-2 rounded-3" (click)="viewDeviceOverview(device)">
            <mat-icon class="m-auto">open_in_browser</mat-icon>
        </button>
    </div>
</mat-card>
