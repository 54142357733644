import { Component } from '@angular/core';

@Component({
  selector: 'app-imole-logo-horizontal',
  templateUrl: './imole-logo-horizontal.component.html',
  styleUrls: ['./imole-logo-horizontal.component.scss']
})
export class ImoleLogoHorizontalComponent {

}
