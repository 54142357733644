<!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
<svg viewBox="0 0 256 256" version="1.1" id="svg12" xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg">
    <defs id="defs12" />
    <path
        d="M 129.27,142.19 43.95,95.37 a 2.31,2.31 0 0 0 -3.43,2 c -0.41,18 -2,93.55 0.09,96 3.31,3.88 85.08,51.16 88.67,51.16 3.59,0 84.53,-48.76 88.67,-51.16 3.66,-2.11 0.86,-78.2 0.15,-96.08 a 2.32,2.32 0 0 0 -3.43,-1.93 z"
        fill="#191919" id="path1" />
    <path
        d="m 129.27,252.5 c -2.47,0 -4.81,0 -48.44,-25.2 -42.92,-24.79 -45.39,-27.68 -46.32,-28.77 -2.38,-2.8 -3.28,-3.85 -2.89,-47.45 0.19,-21.47 0.69,-44.87 0.9,-53.93 a 10.31,10.31 0 0 1 15.28,-8.8 l 81.48,44.71 81.54,-44.74 A 10.32,10.32 0 0 1 226.09,97 c 0.36,9.09 1.23,32.57 1.57,54.15 0.19,12.24 0.17,22 0,29.11 -0.38,12.16 -1,17.37 -5.66,20.07 -0.67,0.39 -3.4,2 -7.5,4.47 -80.02,47.7 -81.87,47.7 -85.23,47.7 z M 48,188.91 c 11.93,8.12 68.16,40.61 81.26,47 8,-4 33.89,-19.1 77,-44.86 l 4.92,-2.94 c 1,-9.16 0.77,-40.76 -0.71,-81.3 l -77.35,42.39 a 8,8 0 0 1 -7.7,0 L 48.31,106.89 c -0.88,41.74 -1.01,73 -0.31,82.02 z"
        fill="#191919" id="path2" />
    <path d="m 40.6,91.49 88.67,-47.29 88.67,47.29 -88.67,48.65 z" fill="#ffffff" id="path3" />
    <path d="m 130.52,41.08 v 95.94 L 41.85,88.36 Z" fill="#e6e6e6" id="path4" />
    <path
        d="M 129.27,142.19 40.6,93.53 c 0,0 -2.38,97 0,99.81 3.31,3.88 85.08,51.16 88.67,51.16 3.59,0 84.53,-48.76 88.67,-51.16 4.14,-2.4 0,-99.81 0,-99.81 z"
        fill="#e83a2a" id="path5" style="fill:#f9a01f;fill-opacity:1" />
    <path
        d="m 129.27,142.19 c -1,2.06 -2,102.81 0,102.31 6.35,-1.56 85.32,-46.93 89,-51.75 1.58,-2.09 2.39,-97.93 -0.31,-99.22 -2.7,-1.29 -87.66,46.6 -88.69,48.66 z"
        fill="#d32920" id="path6" style="fill:#f9a01f;fill-opacity:1" />
    <path
        d="m 217.42,88 c 4.59,-2.53 29.46,-16.33 28.15,-17.09 -1.31,-0.76 -87.67,-45.94 -89.85,-46.6 -2,-0.61 -24.48,14.1 -28.13,16.5 C 123.83,38.2 96.77,19.57 93.69,21.29 90.38,23.09 10.3,67.77 7.43,69.43 4.79,71 34.22,85.79 38.77,88.07 c -4.25,2.15 -29.69,14.59 -28.12,15.49 1.57,0.9 83.82,49.44 88.22,49.92 2.83,0.31 25.8,-14.24 28.82,-16.24 3.38,2.24 31,20.48 33.76,18.92 l 87.13,-50 C 250.19,105.28 222.66,90.72 217.42,88 Z m -89.73,48.86 -87.75,-48.81 87.59,-46.72 88.31,46.58 z"
        fill="#191919" id="path7" />
    <path
        d="m 161.14,164.31 c -4,0 -9.47,-2.68 -20.47,-9.33 -4.79,-2.9 -9.64,-6 -13,-8.2 -2.87,1.8 -6.64,4.14 -10.33,6.33 C 103.93,161 101,161.76 98,161.44 95.44,161.16 92.26,160.82 8.63,111.66 l -2,-1.17 A 8.12,8.12 0 0 1 2.57,103.2 c 0.17,-5.52 4.61,-7.87 14.34,-13 1.38,-0.73 2.82,-1.48 4.25,-2.21 L 17.58,86 C 3.32,78.24 -0.57,75.36 -0.73,69.94 A 8.3,8.3 0 0 1 3.44,62.5 C 5.64,61.23 50.22,36.35 87.23,15.7 l 2.57,-1.43 c 5.16,-2.88 11,-0.32 24.84,8.34 4.81,3 9.61,6.2 13.06,8.54 24.9,-16.2 26.79,-15.62 30.37,-14.52 3.42,1 90.8,46.91 91.51,47.33 a 8.18,8.18 0 0 1 4.04,7.04 c -0.05,5.43 -3.52,7.44 -16.41,14.93 l -3.21,1.86 4.77,2.63 c 14.33,8 17.72,10.07 17.82,15.51 a 8.13,8.13 0 0 1 -4.07,7.2 l -79.15,45.38 -8,4.58 a 8.31,8.31 0 0 1 -4.23,1.22 z m -18.25,-26.76 c 7.55,4.74 14,8.49 17.37,10.08 l 5.21,-3 67.9,-38.93 c -4.31,-2.43 -9.94,-5.51 -16.7,-9.1 z M 26.18,103.4 c 44.25,25.93 66.18,38.21 72.94,41.54 2.91,-1.42 8.15,-4.46 13.18,-7.51 L 39.2,96.8 c -5.51,2.75 -9.76,4.91 -13.02,6.6 z m 30.49,-15.2 71,39.48 L 199,88.1 127.52,50.4 Z M 22.22,70.32 c 4.58,2.57 10.55,5.74 17,9 L 112.5,40.24 A 184,184 0 0 0 94.92,29.72 C 54.29,52.39 33.08,64.24 22.21,70.32 Z m 120.7,-29.91 73.68,38.86 c 5,-2.78 9.58,-5.39 13.34,-7.59 -20.94,-11 -63.28,-33 -74.59,-38.71 -2.62,1.41 -7.08,4.03 -12.43,7.44 z"
        fill="#191919" id="path8" />
    <path
        d="m 218.42,88 c 0.08,-1.3 28.46,-16.33 27.15,-17.09 -1.31,-0.76 -87.67,-45.94 -89.85,-46.6 -2,-0.61 -27.54,16.43 -28.13,16.5 C 127,40.88 96.76,19.53 93.69,21.25 90.38,23.09 10.3,67.77 7.43,69.43 4.79,71 38.7,87.23 38.77,88.07 c 0.07,0.84 -29.69,14.59 -28.13,15.49 1.56,0.9 83.83,49.44 88.23,49.92 2.83,0.31 27.92,-16.24 28.82,-16.24 0.9,0 31,20.48 33.76,18.92 l 87.13,-50 C 250.19,105.28 218.34,89.27 218.42,88 Z m -90.73,48.86 c -1,-0.08 -87.72,-48.14 -87.74,-48.78 -0.02,-0.64 87,-46.63 87.59,-46.72 0.59,-0.09 88.32,45.33 88.31,46.58 -0.01,1.25 -87.15,48.97 -88.16,48.89 z"
        fill="#ffffff" id="path9" />
    <path d="m 128.67,142.67 c -0.67,17.17 -1,77.67 0.61,101.83" fill="#d32920" id="path11" />
    <path
        d="m 129.27,248.5 a 4,4 0 0 1 -4,-3.73 C 123.63,220.09 124,159.55 124.66,142.52 a 4.003002,4.003002 0 1 1 8,0.31 c -0.64,16.44 -1,77.43 0.6,101.41 a 4,4 0 0 1 -3.73,4.26 z"
        fill="#191919" id="path12" />
    <g style="fill:#ffffff;fill-opacity:1" id="g12" transform="matrix(0.13590061,0,0,0.13590061,59.897737,161.61962)">
        <g id="g20" transform="rotate(12.573109,-15.699984,-211.19834)" style="fill:#ffffff;fill-opacity:1">
            <g id="g18" transform="translate(180.69131)" style="fill:#ffffff;fill-opacity:1">
                <path
                    d="M 72.611953,71.06395 53.796679,80.688953 17.385458,68.922794 26.575825,40.482547 62.987046,52.248707 Z"
                    fill="black" id="path1-3" style="fill:#ffffff;fill-opacity:1" />
                <path
                    d="m 95.850412,116.49241 -11.768582,36.41872 -28.447669,-9.19277 11.768582,-36.41871 18.815213,-9.624817 0.0074,0.0024 z"
                    fill="black" id="path2-6" style="fill:#ffffff;fill-opacity:1" />
                <path
                    d="M 129.80607,11.414191 118.03994,47.825338 99.224568,57.45031 99.217168,57.44791 89.592269,38.632573 101.3584,2.2214256 Z"
                    fill="black" id="path3-7" style="fill:#ffffff;fill-opacity:1" />
                <path
                    d="M 89.594689,38.624107 83.092865,58.744466 62.98735,52.247439 45.563398,18.174128 72.173362,4.5680384 Z"
                    fill="black" id="path4-5" style="fill:#ffffff;fill-opacity:1" />
                <path
                    d="M 73.902591,87.184427 67.403073,107.29765 33.342146,124.73401 19.731094,98.114036 53.797076,80.6874 Z"
                    fill="black" id="path5-3" style="fill:#ffffff;fill-opacity:1" />
                <path
                    d="m 168.06256,86.203493 -9.19037,28.440247 -36.41872,-11.76858 -9.62478,-18.81531 18.81515,-9.624939 z"
                    fill="black" id="path6-5" style="fill:#ffffff;fill-opacity:1" />
                <path
                    d="m 95.848985,116.49195 -11.768582,36.41872 -28.447669,-9.19277 11.768582,-36.41872 18.815308,-9.624778 0.0074,0.0024 z"
                    fill="black" id="path7-6" style="fill:#ffffff;fill-opacity:1" />
                <path
                    d="M 129.80465,11.41373 118.03851,47.824877 99.223236,57.44988 99.215836,57.44748 89.590841,38.632112 101.35698,2.2209643 Z"
                    fill="black" id="path8-2" style="fill:#ffffff;fill-opacity:1" />
                <path
                    d="m 165.72014,57.002361 -34.07495,17.431621 -20.10589,-6.49715 6.50182,-20.12036 34.06311,-17.419051 z"
                    fill="black" id="path9-9" style="fill:#ffffff;fill-opacity:1" />
                <path
                    d="m 139.88784,136.94227 -26.61483,13.62112 -17.423506,-34.07337 6.499516,-20.113227 20.1059,6.497147 z"
                    fill="black" id="path10" style="fill:#ffffff;fill-opacity:1" />
            </g>
            <g id="g19" transform="rotate(17.908058,249.49609,172.73448)" style="fill:#ffffff;fill-opacity:1">
                <path d="M 110.05468,193.63063 H 92.789684 v 68.7989 h 17.264996 z" fill="black" id="path11-1"
                    style="fill:#ffffff;fill-opacity:1" />
                <path
                    d="m 302.10168,203.31823 c -3.031,-3.0311 -6.636,-5.4034 -10.807,-7.1171 -4.175,-1.7136 -8.766,-2.5705 -13.773,-2.5705 -5.007,0 -9.604,0.8569 -13.774,2.5705 -4.175,1.7137 -7.776,4.086 -10.807,7.1171 -3.031,3.0311 -5.403,6.6565 -7.117,10.8763 -1.713,4.2197 -2.57,8.8307 -2.57,13.8379 0,5.0072 0.857,9.6232 2.57,13.8381 1.714,4.22 4.086,7.84 7.117,10.876 3.031,3.031 6.632,5.404 10.807,7.117 4.17,1.714 8.767,2.571 13.774,2.571 5.007,0 9.598,-0.857 13.773,-2.571 4.171,-1.713 7.776,-4.086 10.807,-7.117 3.031,-3.031 5.404,-6.656 7.117,-10.876 1.714,-4.2149 2.571,-8.8309 2.571,-13.8381 0,-5.0072 -0.857,-9.6232 -2.571,-13.8379 -1.713,-4.2198 -4.086,-7.8403 -7.117,-10.8763 z m -8.964,32.49 c -0.921,2.2882 -2.174,4.2198 -3.754,5.7993 -1.58,1.58 -3.408,2.794 -5.468,3.626 -2.065,0.837 -4.195,1.253 -6.394,1.253 -2.199,0 -4.438,-0.416 -6.459,-1.253 -2.02,-0.832 -3.823,-2.046 -5.403,-3.626 -1.58,-1.5795 -2.833,-3.5111 -3.754,-5.7993 -0.921,-2.2832 -1.382,-4.8784 -1.382,-7.7758 0,-2.8973 0.461,-5.4926 1.382,-7.7758 0.921,-2.2832 2.174,-4.2197 3.754,-5.7997 1.58,-1.5799 3.383,-2.7884 5.403,-3.6254 2.021,-0.832 4.171,-1.253 6.459,-1.253 2.288,0 4.329,0.416 6.394,1.253 2.065,0.837 3.888,2.0455 5.468,3.6254 1.58,1.58 2.833,3.5165 3.754,5.7997 0.921,2.2882 1.382,4.8785 1.382,7.7758 0,2.8974 -0.461,5.4926 -1.382,7.7758 z"
                    fill="black" id="path12-2" style="fill:#ffffff;fill-opacity:1" />
                <path
                    d="m 229.61368,203.31823 c -2.155,-2.1594 -4.607,-3.987 -7.345,-5.4728 -1.11,-0.5993 -2.259,-1.149 -3.462,-1.6443 -4.176,-1.7136 -8.762,-2.5705 -13.774,-2.5705 -5.012,0 -9.603,0.8569 -13.779,2.5705 -1.198,0.4953 -2.352,1.0401 -3.457,1.6443 -2.743,1.4858 -5.19,3.3134 -7.349,5.4728 -0.372,0.3715 -0.738,0.7578 -1.09,1.149 -0.352,-0.3912 -0.718,-0.7726 -1.09,-1.149 -2.154,-2.1594 -4.606,-3.987 -7.345,-5.4728 -1.109,-0.5993 -2.258,-1.149 -3.462,-1.6443 -4.175,-1.7136 -8.761,-2.5705 -13.773,-2.5705 -5.012,0 -9.604,0.8569 -13.779,2.5705 -1.198,0.4953 -2.352,1.0401 -3.457,1.6443 -2.744,1.4858 -5.19,3.3134 -7.35,5.4728 -3.031,3.0311 -5.403,6.6565 -7.117,10.8713 -1.708,4.2197 -2.565,8.8307 -2.565,13.8429 v 34.4021 h 17.265 v -34.4021 c 0,-2.9023 0.466,-5.4876 1.382,-7.7758 0.921,-2.2832 2.174,-4.2148 3.754,-5.7997 1.585,-1.5799 3.388,-2.7884 5.404,-3.6254 2.02,-0.837 4.175,-1.253 6.463,-1.253 2.288,0 4.324,0.416 6.389,1.253 2.065,0.837 3.893,2.0455 5.473,3.6254 1.58,1.58 2.833,3.5115 3.754,5.7997 0.926,2.2882 1.382,4.8785 1.382,7.7758 v 34.4021 h 17.339 v -34.4021 c 0,-2.9023 0.466,-5.4876 1.382,-7.7758 0.921,-2.2832 2.174,-4.2148 3.754,-5.7997 1.585,-1.5799 3.388,-2.7884 5.404,-3.6254 2.02,-0.837 4.175,-1.253 6.463,-1.253 2.288,0 4.324,0.416 6.389,1.253 2.065,0.837 3.893,2.0455 5.473,3.6254 1.58,1.58 2.833,3.5115 3.754,5.7997 0.926,2.2882 1.382,4.8785 1.382,7.7758 v 34.4021 h 17.27 v -34.4021 c 0,-5.0072 -0.857,-9.6182 -2.57,-13.8429 -1.714,-4.2148 -4.091,-7.8402 -7.117,-10.8713 z"
                    fill="black" id="path13" style="fill:#ffffff;fill-opacity:1" />
                <path d="m 332.87968,168.06033 h -17.265 v 94.3702 h 17.265 z" fill="black" id="path14"
                    style="fill:#ffffff;fill-opacity:1" />
                <path
                    d="m 404.50968,228.03243 c 0,-2.0157 -0.138,-3.9622 -0.421,-5.8442 -0.228,-1.5948 -0.559,-3.1351 -0.99,-4.6358 -0.322,-1.1441 -0.718,-2.2683 -1.154,-3.3629 -1.719,-4.2148 -4.091,-7.8402 -7.122,-10.8713 -3.036,-3.036 -6.637,-5.4034 -10.807,-7.1171 -0.936,-0.3863 -1.897,-0.7281 -2.883,-1.0252 -3.382,-1.0302 -7.008,-1.5453 -10.896,-1.5453 -2.971,0 -5.794,0.3022 -8.474,0.9064 -1.837,0.416 -3.601,0.9707 -5.299,1.6691 -4.175,1.7136 -7.781,4.081 -10.812,7.1171 -3.031,3.031 -5.404,6.6565 -7.117,10.8713 -0.446,1.0945 -0.832,2.2188 -1.154,3.3629 -0.441,1.5006 -0.768,3.0459 -0.996,4.6357 -0.282,1.8821 -0.421,3.8335 -0.421,5.8443 0,2.3476 0.188,4.611 0.575,6.7902 0.272,1.5898 0.649,3.1301 1.129,4.6358 0.262,0.8122 0.555,1.6141 0.872,2.4121 1.713,4.22 4.086,7.845 7.117,10.876 3.031,3.031 6.636,5.404 10.812,7.117 4.17,1.714 8.766,2.571 13.773,2.571 5.007,0 9.599,-0.857 13.774,-2.571 4.17,-1.713 7.771,-4.086 10.807,-7.117 3.031,-3.031 5.403,-6.656 7.122,-10.876 0.317,-0.793 0.609,-1.5999 0.871,-2.4121 h -18.914 c -0.213,0.307 -0.431,0.5943 -0.659,0.8816 -0.356,0.4455 -0.733,0.8665 -1.134,1.2675 -1.585,1.585 -3.403,2.789 -5.473,3.626 -2.065,0.837 -4.195,1.253 -6.389,1.253 -2.194,0 -4.433,-0.416 -6.458,-1.253 -2.026,-0.837 -3.824,-2.041 -5.409,-3.626 -0.648,-0.648 -1.258,-1.3715 -1.793,-2.1491 -0.767,-1.0995 -1.421,-2.313 -1.956,-3.6502 -0.134,-0.3219 -0.247,-0.6538 -0.366,-0.9905 h 49.676 c 0.381,-2.1793 0.569,-4.4427 0.569,-6.7903 z m -50.533,-5.8442 c 0.179,-0.6637 0.402,-1.3125 0.649,-1.9365 0.387,-0.9609 0.837,-1.8623 1.342,-2.6993 0.689,-1.149 1.491,-2.1792 2.407,-3.0955 1.585,-1.5799 3.383,-2.7884 5.409,-3.6254 1.605,-0.6636 3.288,-1.0599 5.062,-1.1985 0.455,-0.0347 0.921,-0.0545 1.396,-0.0545 2.199,0 4.329,0.416 6.389,1.253 2.071,0.837 3.888,2.0455 5.473,3.6254 0.921,0.9163 1.724,1.9465 2.407,3.0955 0.51,0.842 0.961,1.7434 1.347,2.6993 0.248,0.624 0.471,1.2679 0.649,1.9365 h -32.535 z"
                    fill="black" id="path15" style="fill:#ffffff;fill-opacity:1" />
                <path d="m 93.577684,189.30143 -12.103,-21.2411 h 18.12 l 9.443996,21.2411 z" fill="black" id="path16"
                    style="fill:#ffffff;fill-opacity:1" />
                <path d="m 268.02368,189.30143 9.654,-21.2411 h 17.56 l -12.208,21.2411 z" fill="black" id="path17"
                    style="fill:#ffffff;fill-opacity:1" />
                <path d="m 363.70968,189.30143 -12.103,-21.2411 h 18.12 l 9.409,21.2411 z" fill="black" id="path18"
                    style="fill:#ffffff;fill-opacity:1" />
            </g>
        </g>
    </g>
</svg>
