import { Component, Input } from '@angular/core';
import { IDevice } from '../interfaces/device.interface';
import { Router } from '@angular/router';
import { DeviceService } from '../services/device.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TasmotaService } from '../services/tasmota.service';

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss'],
})
export class DeviceComponent {
  @Input() public device!: IDevice;
  public ON_STATE: string = 'ON';

  public lastSeen!: number;

  constructor(
    private readonly router: Router,
    private readonly deviceService: DeviceService,
    private readonly snackbar: MatSnackBar,
    private readonly tasmotaService: TasmotaService
  ) {}

  ngOnInit() {
    this.listenForDevicePower(this.device.id);
  }

  public toggleDevicePowerStatus(e: MatSlideToggleChange) {
    this.tasmotaService
      .toggleDeviceState(this.device.id)
      .subscribe({
        error: (error) => {
          console.error(`[toggleDevicePowerStatus]`, error);

          this.snackbar.open('An error occurred!', 'close', {
            verticalPosition: 'top',
            horizontalPosition: 'end',
            panelClass: ['bg-danger', 'text-white'],
          });
        },
      });
  }

  public listenForDevicePower(id: string) {
    this.deviceService.listenForDevicePower(id).subscribe({
      next: (message) => {
        const powerState = message.payload.toString();
        
        this.device.powered = powerState.toLowerCase().includes(this.ON_STATE.toLowerCase()) ? true : false;
        this.lastSeen = new Date().getTime();
      },
      error: (error) => {
        console.error(`[listenForDevicePower]`, error);
      },
    });
  }

  public get isOnline() {
    return this.now - (this.lastSeen ?? 0) > 10 * 69 * 1000;
  }

  public get now() {
    return new Date().getTime();
  }

  public viewDeviceOverview(device: IDevice) {
    this.router.navigate(['/', 'board', 'devices', 'overview', device.id]);
  }
}
