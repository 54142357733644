import { Inject, Injectable } from '@angular/core';
import { APIBaseURLOption } from '../interfaces/api-base-url.interface';
import { API_BASE_URL_TOKEN } from '../providers/api-base-url.provider';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IDevice } from '../interfaces/device.interface';
import { ICreateDeviceDto } from '../interfaces/create-device.interface';
import { UpdateBody } from '../interfaces/asset';
import { IChannel } from '../interfaces/channel.interface';
import { ICreateChannel } from '../interfaces/create-channel.interface';
import {
  Subject,
  catchError,
  combineLatest,
  concat,
  delay,
  forkJoin,
  map,
  of,
  tap,
  throwError,
  toArray,
} from 'rxjs';
import { MqttService } from 'ngx-mqtt';

export interface SetMQTTDto {
  baseUrl: string;
}

export interface SetWiFiOptionsDto extends SetMQTTDto {
  ssid: string;
  password: string;
}

export interface SetTopicDto extends SetMQTTDto {
  topic: string;
}

export interface IGetAggregatedData {
  id?: string;
  deviceId?: string;
  key: string;
  interval: string;
  start: any;
  end: any;
}

export interface IAggregatedData {
  key?: string;
  value: number;
  time: number;
}

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  private readonly url!: string;
  public headers: { [key: string]: string } = {
    'Content-Type': 'application/json',
  };
  public baseUrl!: string;
  public mqttOptions = {
    mqttHost: 'broker.emqx.io',
    mqttPort: 1883,
    mqttPassword: '',
    mqttUser: '',
    telePeriod: 10,
  };

  constructor(
    @Inject(API_BASE_URL_TOKEN)
    private readonly apiBaseUrlProvider: APIBaseURLOption,
    private readonly httpClient: HttpClient,
    private readonly mqttService: MqttService
  ) {
    this.url = `${this.apiBaseUrlProvider.rootBase}/device`;
  }

  public getNetworkInformation(deviceId: string) {
    const url = `${this.url}/status/network-information`;
    let params = new HttpParams({
      fromObject: {
        deviceId,
        interval: 'interval',
      },
    });

    return this.httpClient
      .get<
        {
          information: {
            bssid: string;
            ssid: string;
            channel: number;
            mode: string;
            rssi: number;
            signal: number;
          };
        }[]
      >(url, {
        params,
        headers: this.headers,
      })
      .pipe(map((response) => response[0]?.information));
  }

  public getLastSeen(deviceId: string) {
    const url = `${this.url}/status/last-seen`;
    let params = new HttpParams({
      fromObject: {
        deviceId,
        interval: 'interval',
      },
    });

    return this.httpClient
      .get<{ name: string; time: string; deviceId: string }[]>(url, {
        params,
        headers: this.headers,
      })
      .pipe(map((response) => response[0]));
  }

  public getTopFourDeviceConsumers(data: IGetAggregatedData) {
    const url = `${this.url}/data/top-four`;
    let params = new HttpParams({
      fromObject: {
        start: data.start,
        end: data.end,
        interval: data.interval,
        key: data.key,
      },
    });

    if (data.deviceId) {
      params = params.append('deviceId', data.deviceId);
    }

    return this.httpClient.get<IAggregatedData[]>(url, {
      params,
      headers: this.headers,
    });
  }

  public getSumOverInterval(data: IGetAggregatedData) {
    const url = `${this.url}/data/sum-over-interval`;
    let params = new HttpParams({
      fromObject: {
        start: data.start,
        end: data.end,
        interval: data.interval,
        key: data.key,
      },
    });

    if (data.deviceId) {
      params = params.append('deviceId', data.deviceId);
    }

    return this.httpClient.get<IAggregatedData[]>(url, {
      params,
      headers: this.headers,
    });
  }

  public getAggregatedSumTotalData(data: IGetAggregatedData) {
    const url = `${this.url}/data/sum`;
    let params = new HttpParams({
      fromObject: {
        start: data.start,
        end: data.end,
        interval: data.interval,
        key: data.key,
      },
    });

    if (data.deviceId) {
      params = params.append('deviceId', data.deviceId);
    }

    return this.httpClient.get<IAggregatedData[]>(url, {
      params,
      headers: this.headers,
    });
  }

  public getAggregatedAverageData(data: IGetAggregatedData) {
    const url = `${this.url}/data/average`;
    let params = new HttpParams({
      fromObject: {
        start: data.start,
        end: data.end,
        interval: data.interval,
        key: data.key,
      },
    });

    if (data.deviceId) {
      params = params.append('deviceId', data.deviceId);
    }

    return this.httpClient.get<IAggregatedData[]>(url, {
      params,
      headers: this.headers,
    });
  }

  public listenForDevicePower(id: string) {
    return this.mqttService.observe(`stat/${id}/POWER`);
  }

  public listenForDeviceState(id: string) {
    return this.mqttService.observe(`tele/${id}/STATE`);
  }

  public updateChannel(id: string, changes: UpdateBody[]) {
    const url = `${this.url}/channel/${id}`;

    return this.httpClient.patch<void>(url, changes, {
      headers: this.headers,
    });
  }

  public deleteChannel(id: string, parentId: string) {
    const url = `${this.url}/channel`;
    let params = new HttpParams();

    if (id) {
      params = params.append('id', id);
    }

    if (parentId) {
      params = params.append('parentId', parentId);
    }

    return this.httpClient.delete<void>(url, {
      headers: this.headers,
    });
  }

  public listChannels(data: Partial<IChannel>, limit: number, offset: number) {
    const url = `${this.url}/channel`;
    let params = new HttpParams({ fromObject: { limit, offset } });

    if (data.id) {
      params = params.append('id', data.id);
    }

    if (data.parentId) {
      params = params.append('parentId', data.parentId);
    }

    if (data.ownerId) {
      params = params.append('ownerId', data.ownerId);
    }

    if (data.position) {
      params = params.append('position', data.position);
    }

    return this.httpClient.get<IChannel[]>(url, {
      params,
      headers: this.headers,
    });
  }

  public addChannel(data: ICreateChannel) {
    const url = `${this.url}/channel`;

    return this.httpClient.post<void>(url, data, {
      headers: this.headers,
    });
  }

  public deleteDevice(id: string) {
    const url = `${this.url}/${id}`;

    return this.httpClient.delete<void>(url, {
      headers: this.headers,
    });
  }

  public updateDevice(id: string, changes: UpdateBody[]) {
    const url = `${this.url}/${id}`;

    return this.httpClient.patch<void>(url, changes, {
      headers: this.headers,
    });
  }

  public listDevices(limit: number, offset: number, id?: string) {
    const url = `${this.url}`;
    let params = new HttpParams({ fromObject: { limit, offset } });

    if (id) {
      params = params.append('id', id);
    }

    return this.httpClient.get<IDevice[]>(url, {
      params,
      headers: this.headers,
    });
  }

  public setTopic(data: SetTopicDto) {
    const url = `${this.url}/setup/topic`;

    return this.httpClient.post<void>(url, data, {
      headers: this.headers,
    });
  }

  public addDevice(data: ICreateDeviceDto) {
    const url = `${this.url}`;

    return this.httpClient.post(url, data, {
      headers: this.headers,
      observe: 'body',
      responseType: 'text',
    });
  }

  public setLocalTopic(data: SetTopicDto) {
    this.baseUrl = data.baseUrl;
    const url = `http://${this.baseUrl}/cm`;

    return this.httpClient
      .post<{ Topic: string }>(url, undefined, {
        params: {
          cmnd: `Topic ${data.topic}`,
        },
        headers: this.headers,
      })
      .pipe(
        map((response) => {
          console.log(`[setTopic]`, response);

          if (response.Topic !== data.topic) {
            return throwError(() => new Error('Topic could not be set'));
          }

          return response;
        })
      );
  }

  // INFO https://github.com/arendst/Tasmota/issues/2131
  public setWiFiOptions(data: SetMQTTDto) {
    const url = `${this.url}/setup/wifi`;

    return this.httpClient.post<void>(url, data, {
      headers: this.headers,
    });
  }

  public setMQTTOptions(data: SetMQTTDto) {
    const url = `${this.url}/setup/mqtt`;

    return this.httpClient.post<void>(url, data, {
      headers: this.headers,
    });
  }

  public setLocalWiFiOptions(data: SetWiFiOptionsDto) {
    this.baseUrl = data.baseUrl;

    return concat(
      this.setWiFiPassword(data.password),
      this.setWiFiSSID(data.ssid)
    ).pipe(
      toArray(),
      tap((values) => console.log(`[setWiFiOptions]`, values)),

      map((values) =>
        values.reduce((prev: any, cur: any) => ({ ...prev, ...cur }), {})
      )
      //   catchError((error) => {
      //     console.error(`[setLocalMQTTOptions]`, error);

      //     return of();
      //   })
    );
  }

  public setWiFiSSID(ssid: string, index = 1) {
    const url = `http://${this.baseUrl}/cm`;

    return this.httpClient
      .post<{ [key: string]: string }>(url, undefined, {
        params: {
          cmnd: `SSId${index} ${ssid}`,
        },
        headers: this.headers,
      })
      .pipe(
        map((response) => {
          console.log(`[setWiFiSSId${index}]`, response);

          if (response[`SSId${index}`] !== ssid) {
            return throwError(() => new Error(`SSId${index} could not be set`));
          }

          return response;
        }),
        catchError((error) => {
          console.error(`[setWiFiSSID]`, error);
          return of();
        })
      );
  }

  public setWiFiPassword(password: string, index = 1) {
    const url = `http://${this.baseUrl}/cm`;

    return this.httpClient
      .post<{ [key: string]: string }>(url, undefined, {
        params: {
          cmnd: `Password${index} ${password}`,
        },
        headers: this.headers,
      })
      .pipe(
        map((response) => {
          console.log(`[setWiFiPassword]`, response);

          if (response[`Password${index}`] !== password) {
            return throwError(
              () => new Error(`Password${index} could not be set`)
            );
          }

          return response;
        }),
        catchError((error) => {
          console.error(`[setWiFiPassword]`, error);
          return of();
        })
      );
  }

  public setLocalMQTTOptions(data: SetMQTTDto) {
    this.baseUrl = data.baseUrl;

    return combineLatest([
      this.setMQTTHost().pipe(delay(5000)),
      this.setMQTTPort().pipe(delay(5000)),
      this.setMQTTUser().pipe(delay(5000)),
      this.setMQTTPassword().pipe(delay(5000)),
      this.setMQTTTelePeriod().pipe(delay(5000)),
    ]).pipe(
      toArray(),
      map((values) => values.reduce((prev, cur) => ({ ...prev, ...cur }), {}))
      // catchError((error) => {
      //   console.error(`[setLocalMQTTOptions]`, error);

      //   return of();
      // }),
    );
  }

  public setMQTTPassword() {
    const url = `http://${this.baseUrl}/cm`;

    return this.httpClient
      .post<{ MqttPassword: string }>(url, undefined, {
        params: {
          cmnd: `MqttPassword ${this.mqttOptions.mqttPassword}`,
        },
        headers: this.headers,
      })
      .pipe(
        map((response) => {
          console.log(`[setMQTTPassword]`, response);

          if (response.MqttPassword !== '*'.repeat(4)) {
            return throwError(
              () => new Error('MQTT Password could not be set')
            );
          }

          return response;
        }),
        catchError((error) => {
          console.error(`[setMQTTPassword]`, error);

          return of();
        })
      );
  }

  public setMQTTUser() {
    const url = `http://${this.baseUrl}/cm`;

    return this.httpClient
      .post<{ MqttUser: string }>(url, undefined, {
        params: {
          cmnd: `MqttUser ${this.mqttOptions.mqttUser}`,
        },
        headers: this.headers,
      })
      .pipe(
        map((response) => {
          console.log(`[setMQTTUser]`, response);

          if (
            this.mqttOptions.mqttUser
              ? response.MqttUser !== this.mqttOptions.mqttUser
              : response.MqttUser !== '%20'
          ) {
            return throwError(() => new Error('MQTT User could not be set'));
          }

          return response;
        }),
        catchError((error) => {
          console.error(`[setMQTTUser]`, error);

          return of();
        })
      );
  }

  public setMQTTPort() {
    const url = `http://${this.baseUrl}/cm`;

    return this.httpClient
      .post<{ MqttPort: number }>(url, undefined, {
        params: {
          cmnd: `MqttPort ${this.mqttOptions.mqttPort}`,
        },
        headers: this.headers,
      })
      .pipe(
        map((response) => {
          console.log(`[setMQTTPort]`, response);

          if (response.MqttPort !== this.mqttOptions.mqttPort) {
            return throwError(() => new Error('MQTT Port could not be set'));
          }

          return response;
        }),
        catchError((error) => {
          console.error(`[setMQTTPort]`, error);

          return of();
        })
      );
  }

  public setMQTTHost() {
    const url = `http://${this.baseUrl}/cm`;

    return this.httpClient
      .post<{ MqttHost: string }>(url, undefined, {
        params: {
          cmnd: `MqttHost ${this.mqttOptions.mqttHost}`,
        },
        headers: this.headers,
      })
      .pipe(
        map((response) => {
          console.log(`[setMQTTHost]`, response);

          if (response.MqttHost !== this.mqttOptions.mqttHost) {
            return throwError(() => new Error('MQTT Host could not be set'));
          }

          return response;
        }),
        catchError((error) => {
          console.error(`[setMQTTHost]`, error);

          return of();
        })
      );
  }

  public setMQTTTelePeriod() {
    const url = `http://${this.baseUrl}/cm`;

    return this.httpClient
      .post<{ TelePeriod: number }>(url, undefined, {
        params: {
          cmnd: `TelePeriod ${this.mqttOptions.telePeriod}`,
        },
        headers: this.headers,
      })
      .pipe(
        map((response) => {
          console.log(`[setMQTTTelePeriod]`, response);

          if (response.TelePeriod !== this.mqttOptions.telePeriod) {
            return throwError(() => new Error('MQTT Host could not be set'));
          }

          return response;
        }),
        catchError((error) => {
          console.error(`[setMQTTHost]`, error);

          return of();
        })
      );
  }
}
