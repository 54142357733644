import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '../services/session.service';

@Injectable({
  providedIn: 'root',
})
export class ActivateChildGuard  {
  constructor(
    private readonly sessionService: SessionService,
    private readonly router: Router
  ) {}

  canActivateChild() {
    try {
      const t = this.sessionService.token;

      console.log(t);

      return t
        ? true
        : this.router.navigate(['/', 'in', 'signin']).then(() => false);
    } catch {
      return this.router.navigate(['/', 'in', 'signin']).then(() => false);
    }
  }
}
