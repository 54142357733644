<form [formGroup]="form" class="d-flex flex-column m-0 p-0">
    <mat-form-field class="w-100" appearance="outline">
        <mat-label class="mat-small">Name</mat-label>
        <input matInput formControlName="name" placeholder="Adepoju" />
        <mat-error *ngIf="controls['name'].invalid">Name is required.</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label class="mat-small">Email</mat-label>
        <input matInput formControlName="email" placeholder="mail@mail.co.uk" type="email" />
        <mat-error *ngIf="controls['email'].invalid">Email is required to contact you.</mat-error>
        <mat-icon matSuffix color="accent">mail_outline</mat-icon>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>Enter your password</mat-label>
        <input matInput formControlName="password" [type]="passwordFieldConfig.password ? 'password' : 'text'" />
        <button mat-icon-button matSuffix (click)="passwordFieldConfig.password = !passwordFieldConfig.password"
            color="accent" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordFieldConfig.password">
            <mat-icon>{{ passwordFieldConfig.password ? "visibility_off" : "visibility" }}</mat-icon>
        </button>
        <mat-error *ngIf="controls['password'].invalid">Password is required.</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>Re-enter your password</mat-label>
        <input matInput formControlName="confirm_password" [type]="passwordFieldConfig.confirm ? 'password' : 'text'" />
        <button mat-icon-button matSuffix (click)="passwordFieldConfig.confirm = !passwordFieldConfig.confirm"
            color="accent" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordFieldConfig.confirm">
            <mat-icon>{{ passwordFieldConfig.confirm ? "visibility_off" : "visibility" }}</mat-icon>
        </button>
        <mat-error *ngIf="controls['confirm_password'].invalid">Please confirm your password again.</mat-error>
        <mat-error *ngIf="controls['confirm_password'].dirty && form.hasError('mismatch')">Passwords do not match.</mat-error>
    </mat-form-field>

    <button mat-raised-button class="mb-3 py-2 rounded-3"
        [ngClass]="{'mt-3': controls['confirm_password'].dirty && form.hasError('mismatch')}" color="accent"
        [disabled]="inProcess || form['invalid']" (click)="signup()">Create</button>

    <p class="d-flex py-2">
        <span class="mat-small">Already have an account?</span>
        <a [routerLink]="['/', 'in', 'signin']" class="ms-2 text-warning">Sign in</a>
    </p>
</form>
