<mat-form-field class="w-100" appearance="outline">
    <mat-label>{{defaultIntervalText}}</mat-label>
    <mat-select [formControl]="intervalControl">
        <mat-option *ngFor="let option of intervals; index as i" [value]="option.name"
            (click)="setApiInterval(option.apiInterval)">{{option.name}}</mat-option>
        <mat-option (click)="openCustomIntervalPicker(picker)">
            Custom
            <!-- <input class="d-flex w-100 h-100" style="display: none!important; visibility: hidden;" matInput [matDatepicker]="picker"> -->
            <!-- <mat-datepicker touchUi #picker></mat-datepicker> -->
            <mat-date-range-input [rangePicker]="picker" style="display: none!important; visibility: hidden;">
                <input class="d-flex w-100 h-100" style="display: none!important; visibility: hidden;" matStartDate
                    placeholder="Start date" (dateChange)="dateRangeChange($event, 'start')">
                <input class="d-flex w-100 h-100" style="display: none!important; visibility: hidden;" matEndDate
                    placeholder="End date" (dateChange)="dateRangeChange($event, 'end')">
            </mat-date-range-input>
            <mat-date-range-picker touchUi #picker (closed)="emitIntervalStringEvent()">
                <mat-date-range-picker-actions>
                    <button mat-button matDateRangePickerCancel class="px-4 py-1 rounded-pill">Cancel</button>
                    <button mat-raised-button class="px-4 py-1 rounded-pill" color="primary"
                        matDateRangePickerApply>Apply</button>
                </mat-date-range-picker-actions>
            </mat-date-range-picker>
        </mat-option>
    </mat-select>
</mat-form-field>
