<div class="d-flex flex-column w-100">
    <button mat-button class="w-fit-content my-2 ps-3 pe-3 rounded-4">
        <mat-icon class="my-auto">arrow_back</mat-icon>
        <span class="ms-1 my-auto">back</span>
    </button>

    <!-- INFO Heading -->
    <h1 class="mt-5 mb-1 mat-h1 fw-bolder">Setup up your smart device</h1>
    <p class="mb-0 mat-body-2">Turn on the smart device. And connect this device (laptop/phone e.t.c) that you are using
        currently to the network (Hotspot) that your smart device created. It looks something like this tasmota_xxxx.
    </p>
    <p class="text-danger">Please do not reload or leave this page during this process until it is completed.</p>

    <!-- INFO Stepper -->
    <mat-stepper class="mt-3 bg-transparent" linear #stepper>
        <!-- INFO Configure Options -->
        <mat-step [editable]="isEditable">
            <div class="d-flex flex-column w-100">
                <span class="mat-h3">Input the IP Address of the smart device</span>

                <!-- INFO IP Address field -->
                <mat-form-field class="col-12 col-sm-8 col-md-6 col-xl-4" appearance="outline">
                    <mat-label class="mat-h3">IP Address</mat-label>
                    <input matInput type="text" [formControl]="ipControl" autocomplete="off">
                    <mat-hint class="fw-bold">It goes like this: xxx.xxx.xxx.xxx</mat-hint>
                </mat-form-field>

                <div class="d-flex w-100 mt-5 ">
                    <button mat-flat-button class="w-fit-content px-5 py-3 rounded-3" color="primary"
                        [disabled]="callInProgress || ipControl.invalid" (click)="saveIPAddress(stepper)">Next</button>
                </div>
            </div>
        </mat-step>

        <!-- INFO Create Device -->
        <mat-step [editable]="isEditable">
            <form [formGroup]="addDeviceForm" class="d-flex flex-column w-100" [autocomplete]="'off'">
                <span class="mat-h3">Add device to your profile</span>

                <!-- INFO Name -->
                <mat-form-field class="col-12 col-sm-8 col-md-6 col-xl-4" appearance="outline">
                    <mat-label class="mat-h3">Name</mat-label>
                    <input matInput type="text" formControlName="name" autocomplete="off">
                    <mat-hint>Give it a name e.g Kitchen Deep Freezer chest</mat-hint>
                </mat-form-field>

                <!-- INFO Powered -->
                <mat-slide-toggle *ngIf="!hasChannels" class="mt-4" formControlName="powered"
                    [checked]="addDeviceForm.controls['powered'].value">Power on</mat-slide-toggle>

                <mat-divider class="col-12 col-sm-8 col-md-6 col-xl-4 my-4"></mat-divider>

                <!-- INFO List of channels -->
                <div *ngIf="channels.length > 0;" class="d-flex flex-column col-12 col-sm-8">
                    <span class="mb-1 mat-h3 fw-bolder">Channels</span>

                    <div class="d-flex w-100 py-3" style="overflow: auto hidden;">
                        <ng-container *ngFor="let channel of channels; index as i;">
                            <div class="d-flex flex-column w-100 me-3 p-2 border border-dark rounded-3" style="min-width: 256px;">
                                <div class="d-flex w-100 mb-1">
                                    <span class="my-auto">{{ channel.name }} at channel {{ channel.position }}</span>
                        
                                    <mat-slide-toggle class="ms-auto my-auto" [checked]="channel.powered"
                                        (change)="channel.powered = $event.checked"></mat-slide-toggle>
                                </div>
                        
                                <button mat-icon-button class="ms-auto rounded-circle" color="warn" (click)="removeChannel(i)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <!-- INFO Has CHANNELS? -->
                <div class="d-flex w-100 mb-3">
                    <mat-checkbox class="my-auto p-0" [checked]="hasChannels" labelPosition="after"
                        (change)="shouldAddChannel($event)">
                        <span class="my-auto mat-caption fw-bold">Has channels?</span>
                    </mat-checkbox>
                </div>

                <!-- INFO Channels -->
                <ng-container *ngIf="hasChannels">
                    <div class="d-flex col-12 col-sm-8 col-md-6 col-xl-4">
                        <!-- INFO Channel Name -->
                        <mat-form-field class="col-9 pe-1" appearance="outline">
                            <mat-label class="mat-h3">Channel Name</mat-label>
                            <input matInput type="text" [formControl]="getControl(channelForm, 'name')"
                                autocomplete="off">
                            <mat-hint>e.g Front Gate light bulb</mat-hint>
                        </mat-form-field>

                        <mat-form-field class="col-3 ps-1" appearance="outline">
                            <mat-label class="mat-h3">Position</mat-label>
                            <input matInput type="number" [formControl]="getControl(channelForm, 'position')"
                                autocomplete="off">
                            <mat-hint>e.g 1</mat-hint>
                        </mat-form-field>
                    </div>

                    <button mat-button class="w-fit-content mt-3 rounded-3" [disabled]="channelForm.invalid"
                        (click)="addChannel()">
                        <mat-icon>add</mat-icon>
                        <span>Add channel</span>
                    </button>
                </ng-container>

                <div class="d-flex w-100" [ngClass]="{'mt-3': !hasChannels, 'mt-5': hasChannels}">
                    <button mat-flat-button class="w-fit-content px-5 py-3 rounded-3" color="primary"
                        [disabled]="callInProgress || addDeviceForm.invalid" (click)="addDevice(stepper)">Save</button>
                </div>
            </form>
        </mat-step>


        <!-- INFO Connect to a network -->
        <mat-step [editable]="isEditable">
            <form [formGroup]="networkForm" class="d-flex flex-column w-100" [autocomplete]="'off'">
                <span class="mat-h3">Provide the network details to connect this smart device to</span>

                <!-- INFO IP SSID -->
                <mat-form-field class="col-12 col-sm-8 col-md-6 col-xl-4" appearance="outline">
                    <mat-label class="mat-h3">SSID (WiFi Name)</mat-label>
                    <input matInput type="text" formControlName="ssid" autocomplete="off">
                    <mat-hint>e.g Home Network</mat-hint>
                </mat-form-field>

                <!-- INFO Network Password -->
                <mat-form-field class="col-12 col-sm-8 col-md-6 col-xl-4 mt-3" appearance="outline">
                    <mat-label class="mat-h3">Network Passphrase (WiFi Password)</mat-label>
                    <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="password"
                        autocomplete="off">
                    <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword"
                        [attr.aria-label]="'hide password'" [attr.aria-pressed]="hidePassword">
                        <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>

                    <mat-hint>Please provide the password to the network. It is not stored anywhere.</mat-hint>
                </mat-form-field>

                <div class="d-flex w-100 mt-5 ">
                    <button mat-flat-button class="w-fit-content px-5 py-3 rounded-3" color="primary"
                        [disabled]="callInProgress || networkForm.invalid"
                        (click)="setWiFiOptions(stepper)">Save</button>
                </div>
            </form>
        </mat-step>
    </mat-stepper>
</div>
