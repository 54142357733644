import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '../services/session.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard  {
  constructor(
    private readonly sessionService: SessionService,
    private readonly router: Router
  ) {}

  async canActivate() {
    try {
      const u = await this.sessionService
        .canLogin();
      
      console.log(u);
      
      return u ? this.router.navigate(['/', 'board', 'summary']).then(() => false) : false;
    } catch {
      return this.router.navigate(['/', 'board', 'summary']).then(() => true);
    }
  }
}
