import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { SHA1 } from 'crypto-js';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent implements OnInit, DoCheck {
  @Input() public data: any[] = [];
  public options: EChartsOption = {
    legend: {
      show: false,
      bottom: 5,
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ({d}%)',
    },
    series: [
      {
        name: 'Radius Mode',
        type: 'pie',
        radius: [20, 140],
        startAngle: 0,
        center: ['50%', '50%'],
        roseType: 'radius',
        itemStyle: {
          borderRadius: 5,
        },
        label: {
          show: false,
        },
        emphasis: {
          label: {
            show: false,
          },
        },
        data: [],
      },
    ],
  };
  public lastHash!: string;

  constructor() {}

  ngOnInit(): void {
    (this.options.series as any)[0] = {
      ...(this.options.series as any)[0],
      data: [...this.data],
    };
  }

  ngDoCheck() {
    const hash = SHA1(JSON.stringify(this.data)).toString();

    if (this.lastHash != hash) {
      this.lastHash = hash;
      this.options = {
        ...this.options,
        series: [{ ...(this.options?.series as any)[0], data: [...this.data] }],
      };
    }

    // (this.options.series as any)[0] = {
    //   ...(this.options.series as any)[0],
    //   data: [...this.data],
    // };
  }
}
