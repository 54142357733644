import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent implements OnInit {
  public links = [
    { path: 'signin', title: 'sign in' },
    { path: 'signup', title: 'sign up' },
  ];
  public activeLink = this.links[0].path;

  constructor() {}

  ngOnInit(): void {}
}
