import { Component, Input } from '@angular/core';
import { IDevice } from '../interfaces/device.interface';

@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.scss'],
})
export class DeviceListComponent {
  @Input() public mainHeader: string = 'Your devices';
  @Input() public devices: IDevice[] = [];

  constructor() {}

  ngOnInit() {}
}
