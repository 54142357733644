import { Component, OnInit } from '@angular/core';
import { AnalyticsOverviewPayload } from '../interfaces/analytics';
import { User } from '../interfaces/user';
import { AnalyticsService } from '../services/analytics.service';
import { AssetsService } from '../services/assets.service';
import { SessionService } from '../services/session.service';
import { UsersService } from '../services/users.service';
import { IDevice } from '../interfaces/device.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceService, IGetAggregatedData } from '../services/device.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit {
  public pieChartData: any[] = [];
  public barChartData: any[] = [];
  private user!: User;
  public count: { users: number; assets: number } = {
    users: 0,
    assets: 0,
  };
  public overviewDataLoaded = false;
  public topFourDataLoaded = false;
  public devices: IDevice[] = [
    // {
    //   id: '1',
    //   name: 'Bedroom Refigerator',
    //   powered: true,
    //   ownerId: 'ownerId',
    //   channels: [],
    //   status: true,
    //   time: new Date().toString(),
    // },
    // {
    //   id: '1',
    //   name: 'Living Room Dispenser',
    //   powered: true,
    //   ownerId: 'ownerId',
    //   channels: [],
    //   status: true,
    //   time: new Date().toString(),
    // },
    // {
    //   id: '1',
    //   name: 'Kitchen Deep freezer',
    //   powered: false,
    //   ownerId: 'ownerId',
    //   channels: [],
    //   status: true,
    //   time: new Date().toString(),
    // },
    // {
    //   id: '2',
    //   name: 'Flood Lights',
    //   powered: false,
    //   channels: [
    //     {
    //       id: '1',
    //       name: 'Gate Flood Light',
    //       powered: true,
    //       status: true,
    //       ownerId: 'ownerId',
    //       time: new Date().toString(),
    //       position: 1,
    //     },
    //     {
    //       id: '1',
    //       name: 'Rear Center Security Light',
    //       powered: true,
    //       status: true,
    //       ownerId: 'ownerId',
    //       time: new Date().toString(),
    //       position: 2,
    //     },
    //   ],
    //   ownerId: 'ownerId',
    //   status: true,
    //   time: new Date().toString(),
    // },
  ];
  public MAX_DEVICE = 4;
  public sumTotalOfEnergy: number = 0;
  public sumTotalOfPower: number = 0;

  constructor(
    private readonly snackbar: MatSnackBar,
    private readonly deviceService: DeviceService
  ) {}

  ngOnInit(): void {
    this.getDevices();
  }

  public intervalChange(
    data: Pick<IGetAggregatedData, 'interval' | 'start' | 'end'>
  ) {
    this.topFourDataLoaded = false;
    this.overviewDataLoaded = false;

    const payload = {
      interval: data.interval,
      start: new Date(data.start as Date).getTime(),
      end: new Date(data.end as Date).getTime(),
    };

    this.getAggregatedAverageData({ ...payload, key: 'energy' });
    this.getEnergySumOverInterval({ ...payload, key: 'energy' });
    this.getPowerSumOverInterval({ ...payload, key: 'power' });
    this.getTopFourDeviceConsumers({ ...payload, key: 'energy' });
  }

  public getTopFourDeviceConsumers(payload: IGetAggregatedData) {
    this.deviceService.getTopFourDeviceConsumers({ ...payload }).subscribe({
      next: (data) => {
        this.topFourDataLoaded = true;

        if (data.length === 0) return;

        this.pieChartData = [...data];
      },
      error: (error) => {
        this.topFourDataLoaded = true;
        console.error(`[getTopFourDeviceConsumers]`, error);

        this.snackbar.open('An error occurred!', 'close', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          panelClass: ['bg-danger', 'text-white'],
        });
      },
    });
  }

  public getPowerSumOverInterval(payload: IGetAggregatedData) {
    this.deviceService.getSumOverInterval({ ...payload }).subscribe({
      next: (data) => {
        if (!data[0]) return;
        this.sumTotalOfPower = data[0].value;
      },
      error: (error) => {
        console.error(`[getSumOverInterval]`, error);

        this.snackbar.open('An error occurred!', 'close', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          panelClass: ['bg-danger', 'text-white'],
        });
      },
    });
  }

  public getEnergySumOverInterval(payload: IGetAggregatedData) {
    this.deviceService.getSumOverInterval({ ...payload }).subscribe({
      next: (data) => {
        if (!data[0]) return;
        this.sumTotalOfEnergy = data[0].value;
      },
      error: (error) => {
        console.error(`[getEnergySumOverInterval]`, error);

        this.snackbar.open('An error occurred!', 'close', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          panelClass: ['bg-danger', 'text-white'],
        });
      },
    });
  }

  public getAggregatedAverageData(payload: IGetAggregatedData) {
    this.deviceService.getAggregatedAverageData(payload).subscribe({
      next: (data) => {
        this.overviewDataLoaded = true;
        this.barChartData = data.map((d) => [d.time, d.value]);
      },
      error: (error) => {
        this.overviewDataLoaded = true;
        console.error(`[getAggregatedAverageData]`, error);

        this.snackbar.open('An error occurred!', 'close', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          panelClass: ['bg-danger', 'text-white'],
        });
      },
    });
  }

  public getDevices() {
    const limit = this.MAX_DEVICE;
    const offset = 0;

    // INFO call api here
    this.deviceService.listDevices(limit, offset).subscribe({
      next: (response) => {
        this.devices = [...this.devices, ...(response || [])];
      },
      error: (error) => {
        console.error(`[getDevices]`, error);

        this.snackbar.open('An error occurred!', 'close', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          panelClass: ['bg-danger', 'text-white'],
        });
      },
    });
  }
}
