<div class="d-flex flex-row flex-wrap w-100 p-0 bg-white" [style]="{'min-height': '100%'}">
    <div class="d-flex col-12 col-md-7 col-lg-6">
        <div class="d-flex flex-column w-100 mx-2 mx-sm-auto py-5 py-md-0 app-onboarding__container">
            <div class="d-flex flex-column mt-auto pt-3 pb-4">
                <app-imole-logo-horizontal class="d-flex d-md-none mx-auto mb-5" [style.width]="'232px'"></app-imole-logo-horizontal>
                
                <span class="mat-headline-4 text-secondary fw-bold mb-0 p-0">Welcome back!</span>
                <span class="mat-body text-dark fw-normal">Please enter your details.</span>
            </div>

            <div class="mb-auto app-onboarding__container-tabs">
                <nav mat-tab-nav-bar [tabPanel]="tabPanel" [mat-stretch-tabs]="false">
                    <a mat-tab-link class="text-capitalize" *ngFor="let link of links"
                        [class]="activeLink == link.path ? 'text-white' : 'text-dark'" (click)="activeLink = link.path"
                        [active]="activeLink == link.path" [routerLink]="[link.path]" routerLinkActive
                        (isActiveChange)="$event ? activeLink = link.path : link.path"> {{link.title}} </a>
                </nav>
                <mat-tab-nav-panel #tabPanel>
                    <div class="py-3 py-sm-4">
                        <router-outlet></router-outlet>
                    </div>
                </mat-tab-nav-panel>
            </div>
        </div>
    </div>
    <div class="d-none d-md-flex flex-md-column col-0 col-md-5 col-lg-6 bg-light app-onboarding__side-panel">
        <app-imole-logo-horizontal class="d-none d-sm-flex w-50 m-auto"></app-imole-logo-horizontal>
    </div>
</div>
