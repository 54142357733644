<form [formGroup]="form" class="d-flex flex-column m-0 p-0">
  <mat-form-field appearance="outline">
    <mat-label class="mat-small">Email</mat-label>
    <input matInput formControlName="email" placeholder="mail@mail.co.uk" type="email" />
    <mat-error *ngIf="controls['email'].invalid">Email is required to contact you.</mat-error>
    <mat-icon matSuffix color="accent">mail_outline</mat-icon>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Enter your password</mat-label>
    <input matInput formControlName="password" [type]="passwordFieldConfig.status ? 'password' : 'text'" />
    <button mat-icon-button matSuffix (click)="passwordFieldConfig.status = !passwordFieldConfig.status" color="accent"
      [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordFieldConfig.status">
      <mat-icon>{{ passwordFieldConfig.status ? "visibility_off" : "visibility" }}</mat-icon>
    </button>
    <mat-error *ngIf="controls['password'].invalid">Password is required.</mat-error>
  </mat-form-field>

  <div class="d-flex flex-row mb-3">
    <span class="ms-auto text-primary">Forgot password?</span>
  </div>

  <button mat-raised-button class="mb-3 py-2 rounded-3" color="accent" [disabled]="inProcess || form['invalid']"
    (click)="signIn()">Continue</button>

  <p class="d-flex py-2">
    <span class="mat-small">Don't have an account?</span>
    <a [routerLink]="['/', 'in', 'signup']" class="ms-2 text-warning">Sign up</a>
  </p>
</form>
