<div class="d-flex flex-column py-sm-3">
    <p class="mt-3 mb-1 mat-headline text-capitalize">Users</p>
    <span class="mb-3 mat-subheading-1">Your customers and admin users...</span>

    <div *ngIf="users.length > 0; else noItems" class="d-flex flex-wrap w-100 py-3">
        <div *ngFor="let u of users" class="col-12 col-md-6 pe-md-1 ps-md-1">
            <mat-card matRipple class="d-flex mb-2 p-0 shadow-sm rounded-3" style="cursor: pointer;">
                <img class="rounded-3" src="https://randomuser.me/api/portraits/women/50.jpg" alt="user-image"
                    [style]="{width: '64px'}">
                <div class="d-flex flex-column p-2">
                    <!-- <span class="mb-1 mat-subheading-1 text-primary fw-bold">{{u.firstname}} {{u?.lastname}}</span> -->

                    <div class="mat-small">
                        <span class="text-gray">{{ u.email }}</span> | <span class="text-gray">{{ u.time |
                            date:'longDate' }}</span>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>

    <ng-template #noItems>
        <mat-card class="d-flex m-auto py-4 w-100 rounded-3">
            <mat-icon class="ms-auto my-auto material-icons-outlined" color="warn">hourglass_empty</mat-icon>
            <span class="ms-2 me-auto my-auto mat-h4">No Users available...</span>
        </mat-card>
    </ng-template>
</div>
