import { Injectable } from '@angular/core';
import { MqttService } from 'ngx-mqtt';

@Injectable({
  providedIn: 'root',
})
export class TasmotaService {
  constructor(private readonly mqttService: MqttService) { }

  public toggleDeviceState(id: string) {
    return this.mqttService.publish(`cmnd/${id}/POWER`, 'Toggle', { qos: 2 });
  }
}
