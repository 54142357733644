import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ISignup } from '../interfaces/authentication-data';
import { API_BASE_URL_TOKEN } from '../providers/api-base-url.provider';
import { APIBaseURLOption } from '../interfaces/api-base-url.interface';

@Injectable({
  providedIn: 'root',
})
export class SignupService {
  private readonly url!: string;
  public headers: { [key: string]: string } = {
    'Content-Type': 'application/json',
  };

  constructor(
    private http: HttpClient,
    @Inject(API_BASE_URL_TOKEN)
    private readonly apiBaseUrlProvider: APIBaseURLOption
  ) {
    this.url = `${this.apiBaseUrlProvider.rootBase}/user`;
  }

  public createProfile() {
    return this.http.post(`${this.url}/profile`, null, {
      headers: this.headers,
    });
  }
}
