import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { FirebaseAuthService } from './firebase-auth.service';
import { ONBOARDING_ID_TOKEN_KEY } from '../constants/local-storage.constant';

@Injectable({
  providedIn: 'root',
})
export class SessionService {

  constructor(private readonly firebaseAuthService: FirebaseAuthService) { }

  public getLoggedInUser() {
    return JSON.parse(localStorage.getItem(ONBOARDING_ID_TOKEN_KEY)!);
  }

  public removeSession() {
    localStorage.removeItem(ONBOARDING_ID_TOKEN_KEY);
  }

  public get token() {
    return localStorage.getItem(ONBOARDING_ID_TOKEN_KEY);
  }

  public canLogin() {
    return lastValueFrom(this.firebaseAuthService.getUserProfile());
  }
}
