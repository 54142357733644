<div class="d-flex flex-column w-100">
    <!-- INFO Add new device Button -->
    <div class="d-flex w-100 mb-3 py-5">
        <a mat-flat-button [routerLink]="['/', 'board', 'devices', 'setup']"
            class="ms-auto rounded-3 text-white" color="primary">
            <mat-icon class="my-auto">add</mat-icon>
            <span class="my-auto">Add new device</span>
        </a>
    </div>

    <ng-container *ngIf="devices.length > 0; else noDevices">
        <app-device-list mainHeader="My Devices" [devices]="devices"></app-device-list>
    </ng-container>

    <ng-template #noDevices>
        <div class="d-flex flex-column w-100 my-5">
            <app-box-empty class="mx-auto" style="width: 256px; height: 256px;"></app-box-empty>
            <span class="mx-auto mt-3 mat-subtitle-1">No devices available... Please wait</span>
        </div>
    </ng-template>
    
    <button mat-stroked-button class="mx-auto mt-5 mb-3 px-5 py-4 rounded-3" (click)="getDevices()">See all devices</button>
</div>
